import Header from "./components/header";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/footer";
import Home from "./components/home";
import Faqs from "./components/faq";
import Form from "./components/form";
import Feature from "./components/feature";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/pricing" element={<Faqs />} />
        <Route path="/demo" element={<Form />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer />
      <style jsx="">{`
        .App {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
      `}</style>
    </div>
  );
}

export default App;
